import React from 'react'
import Layout from '../components/Layout'
import { Link } from 'gatsby'

import { pageLink, conversionId, googleConversion } from '../utils'

import andrewHeadImg from '../assets/images/andrew-headshot.jpeg';
import twitterImg from '../assets/images/twitter.jpeg';

import TelegramSvg from '../assets/svg/telegram.svg';
import TwitterSvg from '../assets/svg/twitter.svg';
import DiscordSvg from '../assets/svg/discord.svg';
import GitHubSvg from '../assets/svg/github.svg';
import MediumSvg from '../assets/svg/medium.svg';
import YoutubeSvg from '../assets/svg/youtube.svg';
import StackOverFlowSvg from '../assets/Svg/stackoverflow.svg';
import MailSvg from '../assets/Svg/mail.svg';


import './community.scss';


// DATA --------
const newsBoxesData =  [
  {
    imgSrc: andrewHeadImg,
    text: 'Our founder and CEO, Andrew Kwan spoke at TEDx, sharing his vision on the importance of ensuring Web3 and Metaverse serve all users, remain open and are used for good.',
    buttonText: "Read More",
    href: pageLink.tedx,
    imgAlt: "Andrew Kwan"
  }, {
    imgSrc: twitterImg,
    text: 'We regularly share demos, exclusive news and updates about our token launch on Twitter. Connect with us there for early access and special offers.',
    buttonText: "Read More",
    href: pageLink.twitter,
    imgAlt: "Twitter"
  },
]

const socialBoxes = [
  {
    icon: <TelegramSvg />,
    text: 'Lorem ipsum dolor sit amet, consectetur',
    buttonText: "Telegram",
    href: pageLink.telegram ,
    gId: conversionId.telegram,
  },
  {
    icon: <TwitterSvg />,
    text: 'Lorem ipsum dolor sit amet, consectetur',
    buttonText: "Twitter",
    href: pageLink.twitter,
    gId: conversionId.twitter,
  },
  {
    icon: <DiscordSvg />,
    text: 'Lorem ipsum dolor sit amet, consectetur',
    buttonText: "Discord",
    href: pageLink.discord,
    gId: conversionId.discord,
  },
  {
    icon: <GitHubSvg/> ,
    text: 'Lorem ipsum dolor sit amet, consectetur',
    buttonText: "Github",
    href: pageLink.github,
    gid: conversionId.githubExploreTheCode,
  },
  {
    icon: <MediumSvg />,
    text: 'Lorem ipsum dolor sit amet, consectetur',
    buttonText: "Medium",
    href: pageLink.medium,
    gId: conversionId.medium,
  },
  {
    icon: <YoutubeSvg />,
    text: 'Lorem ipsum dolor sit amet, consectetur',
    buttonText: "Youtube",
    href: pageLink.youtube,
    gId: conversionId.youtube,
  },
  {
    icon: <StackOverFlowSvg />,
    text: 'Lorem ipsum dolor sit amet, consectetur',
    buttonText: "Stack Overflow",
    href: pageLink.stackoverflow,
    gId: conversionId.stackOverflow,
  },
  {
    icon: <MailSvg />,
    text: 'Lorem ipsum dolor sit amet, consectetur',
    buttonText: "Email",
    href: pageLink.email,
    gId: conversionId.email,
  },
]
// END DATA


// component ----------
const SocialBox = ({icon, text, buttonText, href, gId}) => {
  return (
    <a 
      href={ href } 
      target="_blank" 
      rel="noreferrer" 
      className={`social-box ${buttonText.toLowerCase().replace(' ', '-')}`}
      onClick={() => googleConversion(gId)}
    >
      <div className={`social-box__top-section ${buttonText.toLowerCase().replace(' ', '-')}`}>
        {icon}
      </div>
      <div className={`social-box__text ${buttonText.toLowerCase().replace(' ', '-')}`}>
        {buttonText}
      </div>
      {/* <a href={ href } className="social-box__link button" target="_blank" rel="noreferrer">
      </a> */}
    </a>
  )
}

const NewsBox = ({ imgSrc, text, buttonText, href, imgAlt }) => {
  return (
    <div className="news-box">
      <img src={imgSrc} alt={imgAlt} />
      <p>{ text }</p>
      <a className="button" href={href} target="_blank" rel="noreferrer" aria-label={buttonText}>{buttonText}</a>
    </div>
  )
}


const CommunityPage = () => {
  return (
    <Layout
      title="Community | Metaverse Builders | MHTTP"
      pageClassName="page-community"
      description="The MHTTP Community is composed of innovators and metaverse developers who believe that Metaverse access and immersive portals are the future"
    >
      <section className="page-community__hero">
        <div className="page-community__hero__container">
          <div className="page-community__hero__container--left">
            <h1 className="page-title">MHTTP Community</h1>
            <p>Our community channels are a hub for developers, token holders and supporters to stay connected and informed.</p>
          </div>
        </div>
      </section>
      <section className="page-community__connect">
        <section className="page-community__connect__container">
          <h1 className="title">Connect with MHTTP</h1>
          <p className="title-tag">We post to these channels regularly and will continue to diversify our community building efforts as we grow.</p>
          <div className="page-community__connect__container__social-boxes">
            {socialBoxes.map(socialBox => (
              <SocialBox 
                key={socialBox.buttonText}
                icon={socialBox.icon}
                // text={socialBox.text}
                buttonText={socialBox.buttonText}
                href={socialBox.href}
                gId={socialBox.gId}
              />
            ))}
          </div>
        </section>
      </section>
      <section className="page-community__news">
        <div className="page-community__news__container">
          <h1 className="title">News</h1>
          <p className="title-tag">Hear from our CEO and industry peers, as well as all of the latest MHTTP media coverage.</p>
          <div className="page-community__news__container__news-boxes">
            {newsBoxesData.map(newsBox => (
              <NewsBox
                key={newsBox.text}
                imgSrc={newsBox.imgSrc}
                text={newsBox.text}
                buttonText={newsBox.buttonText}
                href={newsBox.href}
                imgAlt={newsBox.imgAlt}
              /> 
            ))}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default CommunityPage
